<template>
  <div class="about-wrap">
    <div class="about">
      <p>
        On behalf of Sable Valley (the "Company"), and in association of RL Grime, we are pleased to offer you membership with Sable Valley, effective immediately.

      </p>  <p>

        During your membership here at Sable Valley, we strive to provide you with the technical information, advocacy, and professional opportunities to help advance your overall well-being. As a member, you will be able to exchange research information with other Sable Valley affiliates and participate in programming sessions offered at various conferences held by Sable Valley throughout the year.

      </p>  <p>

        We are excited by the prospect of you joining Sable Valley.


      </p>  <p>
        The Time For A Solution Is Now!

      </p>  <p>

        Sincerely,<br/>

        Sable Valley
      </p>

    </div>
    <div class="form">

      <div class="success" v-if="submitted">
        Submitted!
      </div>
      <form @submit.prevent="onSubmit()"  v-if="!submitted">
        <div class="questions">
          <div class="question">
            Name<br/>
            <input type="text" name="name" placeholder="" v-model="submission.name" v-validate="'required|url'">
          </div>
          <div class="question">
            Email<br/>
            <input type="email" name="email" placeholder="" v-model="submission.email" v-validate="'required|email'">
          </div>
          <div class="question">
            Address<br/>
            <input type="text" name="address" placeholder="" v-model="submission.address" v-validate="'required'">
          </div>
          <div class="question">
            Phone<br/>
            <input type="tel" name="tel" placeholder="" v-model="submission.phone" v-validate="'required'">
          </div>
        </div>
        <div class="submit">
          <button type="submit" name="button">Submit</button>
        </div>
      </form>
    </div>

  </div>

</template>

<script>
import { SVMember } from '@/util/myParse';

export default {
  name: 'Membership',
  props: {},
  data() {
    return {
      submitted: false,
      submitting: false,
      submission: {
        name: '',
        email: '',
        address: '',
        phone: '',
      },
    };
  },
  methods: {
    onSubmit() {
      if (this.submitting) {
        return;
      }
      this.submitting = true;
      const mem = new SVMember();
      mem.save(this.submission).then((result) => {
        this.submitting = false;
        this.submitted = true;
      }, (error) => {
        this.submitting = false;
      });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.success {
  font-weight: bold;
  text-align: center;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.about-wrap {
  display: flex;
  padding: $mobileMargin;
  flex-direction: column;
  padding-top: 12rem;
  padding-bottom: 3rem;
  @include desktop {
    flex-direction: row;
  }
}
.about {
  flex: 1 0 50%;

  font-family: 'title';
  font-size: 0.8rem;
  text-align: left;
  margin: 0 auto 0;
  @include desktop {
    max-width: 50vw;
  }
  p {
    padding: 0 1rem;
    margin-bottom: 1rem;
    text-shadow: 0.5px 0.1px 0px #7100002e, 0px 1px 1px #5fa00a6b;
    max-width: 30rem;

  }
}
.form {
  font-size: 0.8rem;
  flex: 1 0 50%;

  .questions {
    padding-left: 1rem;
    max-width: 30rem;
    .question {
      padding-top: 1rem;
      text-align: left;
      input {
        font-size: 0.8rem;
        color: white;
        background: black;
        padding: 0.65em 1em;
        box-shadow: 0px 2px blue;
        width: 100%;
        font-family: 'title';
        border: 2px solid black;
        text-shadow: 1px 0px 3px;

        &:focus {
          background: #2D37D3;
        }
      }
    }
  }
  .submit {
    padding: 1rem;
    padding-top: 2rem;
    button {
      padding: 1rem;
      background: black;
      color: white;
      font-family: 'title';
      font-weight: bold;
      font-size: 0.8rem;
      cursor: pointer;
      box-shadow: 0px 2px blue;

    }
  }
}
</style>
