import Vue from 'vue';
import VueClipboards from 'vue-clipboards';
import VueRouter from 'vue-router';
import VeeValidate from 'vee-validate';

import App from './App.vue';
import router from './routes';

Vue.use(VeeValidate, {
  events: '',
});
Vue.use(VueRouter);
Vue.use(VueClipboards);
Vue.config.productionTip = false;

new Vue({
  render: h => h(App),
  router,
}).$mount('#app');
