<template>
  <div class="video-wrap">
    Coming Soon
  </div>

</template>

<script>


export default {
  name: 'Home',
  props: {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.video-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100%;
}
.video {
  text-align: center;
  padding: 0 $mobileMargin;
  @include desktop {
    padding: 0 $margin;
  }
  width: 100%;
  iframe {
    width: 100%;
    border: none;
    margin: 0 auto;
    height: 56vw;
    @include desktop {
      height: 50vh;
      max-width: 88.88vh;
    }
  }
  margin: 0 auto;

}

</style>
