<template lang="html">
  <div class="about-wrap">
    <p>
      We live in a world, that is filled with constant Stimulation. A world where sound travels interpersonally and new ideas are shared through exchanges of frequency. As new patterns emerge, we are faced with the choice of tuning in
      or phasing out with the passage of time.
    </p>

    <p>
      The Time For A Solution Is Now!
    </p>

    <p>
      Through meticulous research we have arrived at a process of recording and delivering a new type of auditory experience, and for the first time this work is being made public to you the listener. We hope that you will join us in welcoming a new way to co-exist through sound.
    </p>


    <p>
      Brought to you by Sable Valley & the Sable Valley institute, in association with RL Grime.
    </p>
    <br>
    <div class="social-links">
      <a href="https://www.instagram.com/sablevalley/" target="_blank">Instagram</a>
      /
      <a href="https://twitter.com/sable_valley" target="_blank">Twitter</a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'About',
};
</script>

<style lang="scss" scoped>
.social-links {
  a {
    color: black;
  }
}
.about-wrap {
  padding: $mobileMargin;
  padding-top: 12rem;
  padding-bottom: 2rem;
  font-family: 'title';
  font-size: 0.8rem;
text-align: center;
  margin: 0 auto 0;
  @include desktop {
    max-width: 50vw;
  }
  p {
    padding: 0 1rem;
    margin-bottom: 1rem;
    text-shadow: 0.5px 0.1px 0px #7100002e, 0px 1px 1px #5fa00a6b;

  }

}
</style>
