import Parse from 'parse';

Parse.initialize('ZpvOGcD2p6pR1p6nfzDSas9TNDVOPYehWO4QcNq5', 'JlBJ02m3CEhN1zWfJpI1uEbs5PHQOqhWNhDYNVI4');

Parse.serverURL = 'https://pg-app-y0207u7c78x006yvzx0kj5lac4vr2z.scalabl.cloud/1/';


const myParse = Parse;

export const SVSubmission = Parse.Object.extend('SableValleySubmission');
export const SVMember = Parse.Object.extend('SableValleyMember');

export default myParse;
