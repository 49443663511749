<template>
  <div class="apply-wrap">
    <div class="apply-inner">
      <div class="application">
        <div class="title" :style="{'background-image': `url(${applyNowTitle})`}">
          APPLY NOW:
        </div>
        <div class="success" v-if="submitted">
          Application Submitted Successfully.
        </div>

        <form class="" @submit.prevent="onSubmit()" v-if="!submitted">
          <div class="error-list" v-if="errors.all().length">
            Error:
            <!-- <div class="error" v-for="error in errors.all()">{{ error }}</div> -->
          </div>
          <div class="questions">
            <div class="question">
              Link to submission (Soundcloud preferred)<br/>
              <input type="text" name="demo url" placeholder="http://" v-model="submission.url" v-validate="'required|url'">
            </div>
            <div class="double-question">
              <div class="question">
                Age<br/>
                <select ref="ageSelect" v-model="submission.age" v-validate="'required'" name="age">
                  <option disabled value=""></option>
                  <option v-for="(num, i) in ages" v-bind:key="num">{{i + 3}}</option>
                </select>
              </div>
              <div class="question">
                Location<br/>
                <select ref="locationSelect" class="" name="" v-model="submission.location">
                  <option disabled value=""></option>
                  <option v-for="country in countries" v-bind:key="country">{{country}}</option>
                </select>
              </div>
            </div>

            <div class="question">
              In 100 characters or less, describe your submission
              <div class="character-count">
                {{submission.description.length}} / 100
              </div>
              <input type="text" :maxlength="100" v-model="submission.description" v-validate="'required|max:100'" name="description">
            </div>

            <div class="question">
              Link to three images that you associate with this submission<br/>
              <input type="text" placeholder="http://" v-model="submission.image1"><br/>
              <input type="text" placeholder="http://" v-model="submission.image2"><br/>
              <input type="text" placeholder="http://" v-model="submission.image3">
            </div>
            <div class="double-question">
              <div class="question">
                How much water do you consume in a day?<br/>
                <select ref="waterSelect" v-model="submission.water" v-validate="'required'" name="water">
                  <option>0 cups</option>
                  <option>1 cup</option>
                  <option>2-3 cups</option>
                  <option>3-5 cups</option>
                  <option>5+ cups</option>
                </select>
              </div>
            </div>
            <div class="question">
              Choose one
              <div class="image-selections">
                <button
                  type="button"
                  name="button"
                  :class="{'selected': submission.IQSelection === 1}"
                  @click="selectIQ(1)"
                >
                  <div class="image-selection">
                    1
                  </div>
                </button>
                <button
                  type="button"
                  name="button"
                  :class="{'selected': submission.IQSelection === 2}"
                  @click="selectIQ(2)"
                >
                  <div class="image-selection">
                    2
                  </div>
                </button>
                <button
                  type="button"
                  name="button"
                  :class="{'selected': submission.IQSelection === 3}"
                  @click="selectIQ(3)"
                >
                  <div class="image-selection">
                    3
                  </div>
                </button>
                <button
                  type="button"
                  name="button"
                  :class="{'selected': submission.IQSelection === 4}"
                  @click="selectIQ(4)"
                >
                  <div class="image-selection">
                    4
                  </div>
                </button>
              </div>
            </div>
            <div class="question">
              Soundcloud<br/>
              <input type="text"  placeholder="http://"  v-model="submission.soundcloudSocial">
            </div>
            <div class="question">
              Instagram<br/>
              <input type="text"  placeholder="http://"  v-model="submission.instagramSocial">
            </div>
            <div class="question">
              Twitter<br/>
              <input type="text"  placeholder="http://"  v-model="submission.twitterSocial">
            </div>
            <div class="question">
              Email<br/>
              <input type="email" name="email" placeholder="" v-model="submission.email" v-validate="'required|email'">
            </div>
          </div>
          <div class="submit">
            <button type="submit" name="button">SUBMIT APPLICATION</button>
          </div>
        </form>
        </div>
      </div>
  </div>
</template>

<script>
import applyNowTitle from '@/assets/applynow.png';
import countries from '@/countries';
import { SVSubmission } from '@/util/myParse';

console.log(applyNowTitle);

export default {
  name: 'ApplicationForm',
  data() {
    return {
      submitted: false,
      submitting: false,
      countries,
      applyNowTitle,
      ages: Array(97),
      submission: {
        url: '',
        age: '',
        location: '',
        description: '',
        image1: '',
        image2: '',
        image3: '',
        water: '',
        IQSelection: false,
        soundcloudSocial: '',
        twitterSocial: '',
        instagramSocial: '',
        email: '',
      },
    };
  },
  mounted() {

  },
  methods: {
    selectIQ(opt) {
      this.submission.IQSelection = opt;
    },
    onSubmit() {
      if (this.submitting) {
        return;
      }
      this.submitting = true;
      this.$validator.validate().then((result) => {
        if (!result) {
          window.scrollTo(0, 0);
          this.submitting = false;
        } else {
          const sub = new SVSubmission();
          sub.save(this.submission).then((result1) => {
            this.submitting = false;
            this.submitted = true;
          }, (error) => {
            this.submitting = false;
          });
        }
      });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.character-count {
  float: right;
}
.success {
  text-align: center;
}
.error-list {
  color: red;
  font-weight: normal;
  .error {
    background: rgba(255, 104, 243, 0.4);
    padding: 0.5rem;
  }
  padding-bottom: 1rem;
}
.submit {
  text-align: center;
  padding-top: 2rem;

  button {
    padding: 1rem;
    background: black;
    color: white;
    font-family: 'title';
    font-weight: bold;
    font-size: 1rem;
    cursor: pointer;
    box-shadow: 0px 2px blue;

  }
}
.image-selections {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;

  button {
    width: 50%;
    height: 10rem;
    padding: 0.5rem;
    flex: 0 1 50%;
    background: transparent;
    cursor: pointer;
    &:hover {
      .image-selection {
        transform: scale(1.1);

      }
    }
    &:nth-child(1) {
      .image-selection {
        background-image: url('../assets/symbol1.png');
      }
    }
    &:nth-child(2) {
      .image-selection {
        background-image: url('../assets/symbol2.png');
      }
    }
    &:nth-child(3) {
      .image-selection {
        background-image: url('../assets/symbol3.png');
      }
    }
    &:nth-child(4) {
      .image-selection {
        background-image: url('../assets/symbol4.png');
      }
    }

    &.selected {
      background: radial-gradient(5rem, #f5f5ff, transparent);

      .image-selection {
        transform: scale(0.6);
        // border: 5px solid white;
      }
    }
    &:nth-child(2n) {
      padding-right: 0;
    }
    &:nth-child(2n + 1) {
      padding-left: 0;
    }
  }
  .image-selection {
    transition: 200ms $easeOutBack;
    // background: black;
    color: transparent;
    width: 100%;
    height: 100%;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
  }
}
.apply-wrap {
  padding: $mobileMargin;
  font-family: 'title';
  font-size: 0.8rem;
  // font-weight: bold;
  .apply-inner {
    padding-top: 4rem;
    max-width: 40rem;
    margin: 0 auto;
    width: 100%;
    padding-top: 8rem;
    .double-question {
      display: flex;
      flex-direction: row;
      margin-bottom: 0.5rem;

      .question {
        width: 50%;
        flex: 0 0 50%;
        &:first-child {
          padding-right: 1rem;
        }
        &:nth-child(2) {
          padding-left: 1rem;
        }
      }
    }
    .question {
      input {
        box-shadow: 0px 2px blue;
        margin-bottom: 0.5rem;
        text-shadow: 1px 0px 3px;
        margin-top: 0.25rem;
        font-size: 0.8rem;

        &:focus {
          background: #2D37D3;
        }
      }
      select {
        margin-top: 0.25rem;
        box-shadow: 0px 2px blue;
        background: black;
        color: white;
        border-radius: 0;
        -webkit-appearance: none;
        padding: 0.65rem 1rem;
        text-shadow: 1px 0px 3px;
        box-shadow: 0px 2px blue;
        font-size: 1rem;
        width: 100%;
        font-size: 0.8rem;

      }
      input[aria-invalid="true"],
      select[aria-invalid="true"] {
        box-shadow: 0px 2px red;

      }

    }
    input {
      background: black;
      color: white;
      padding: 0.5em 1em;
    }
    input,
    textarea {
      width: 100%;
      font-family: 'title';
      border: 2px solid black;
      padding: 0.5em 1em;
      font-size: 1rem;
    }
    .title {
      font-family: 'title';
      font-size: 2rem;
      text-align: center;
      padding: 2rem 0;
      color: transparent;
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      max-width: 10rem;
      margin: 0 auto;
    }
    .question {
      margin-bottom: 0.5rem;

    }
  }
}
</style>
