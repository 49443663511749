<template>
<div class="posts">
  <div class="iframe-wrap">
    <iframe id="laylo-drop-f5616abb-6284-4f2e-8555-163449548eef" frameborder="0" scrolling="no" allow="web-share" allowtransparency="true" style="width: 1px; min-width: 100%; max-width: 1000px" src="https://embed.laylo.com?dropId=f5616abb-6284-4f2e-8555-163449548eef&color=cee707&minimal=true&theme=dark">
    </iframe>
  </div>
  <div class="wrap" v-if="featuredImage">
    <div class="post-large" :style="{ 'animation-delay': (0) + 'ms' }">
      <div class="post-image post-image-large" :style="{ 'background-image': 'url(' + CommunityOutreachPoster + ')' }">
      </div>
    </div>

  </div>

  <div class="wrap" v-if="featuredTour">
    <div class="tour-dates">
      <div class="tour-dates-wrap" style="width:100%">

        <div v-for="show in tourDates" v-bind:key="show.date">
          <a v-if="!show.blurred" class="tour-date" :href="show.URL" target="_blank">
            <div class="show-date">{{ show.date }}</div>
            <div class="show-city">{{ show.city }}</div>
            <div class="show-venue">{{ show.venue }}</div>
            <div class="show-artists">Tickets</div>
          </a>
          <div v-if="show.blurred" class="">
            <div class="blurred-show">

            </div>
          </div>

        </div>
      </div>

    </div>
    <div class="lines"></div>
    <div class="lines"></div>

  </div>

  <div class="wrap">
    <br />

    <a class="post" :href="post.link" target="_blank" v-for="(post, i) in posts" :style="{ 'animation-delay': (i * 100) + 'ms' }" v-bind:key="post.link">
      <div class="post-image" :style="{ 'background-image': 'url(' + post.image + ')' }">

      </div>
    </a>

    <br />
  </div>
  <div class="section-black">
    <div class="iframe-wrap">
      <iframe src="https://www.youtube.com/embed/gTAvRsDJe7w" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>
    </div>
  </div>

</div>
</template>

<script>
import Halloween from '@/assets/HalloweenIX.jpg';
import Halloween2 from '@/assets/Halloween2.jpg';
import HalloweenX from '@/assets/HalloweenX.png';
import HalloweenXI from '@/assets/HalloweenXI.png';
import SummerVol3 from '@/assets/SummerVol3.jpg';
import niteharts from '@/assets/niteharts_cover_4000x4000.jpg';
import floorspace from '@/assets/floorspace.png';
import Monarch from '@/assets/PaulineHerr-Monarch_Final.jpg';
import WINK from '@/assets/WINK.jpg';
import SVSummer from '@/assets/SVSv1.jpg';
import Montell from '@/assets/Montell2099_EP_CoverFinal_UPDATED.jpg';
import Juelz from '@/assets/Juelz_EP_Final.jpg';
import CommunityOutreachPoster from '@/assets/communityoutreachtour.jpg';
import FinalOutreachPoster from '@/assets/78BAD140-A4C8-48A4-84AE-40FFFEF54038.jpeg';
import SVSummerVol4 from '@/assets/SV-SummerV4-Front_Final.jpg';

import dogwater from '@/assets/JAWNS-Dogwater_FINAL.jpg';
import controlfreak from '@/assets/ControlFreak-NOFORTUNE_FINAL.jpg';
import playremaster from '@/assets/Play_Final_4k_Remaster_F.png';
import deadcrow from '@/assets/Deadcrow-REACTOR_EPFinal.jpg';
import monarchRemixes from '@/assets/PaulineHerr-MonarchRemixes_Final.jpg';
import forwardOnly from '@/assets/Sublab-ForwardOnly-Final.jpg';
import rmxs from '@/assets/FloorspaceRMX-S.png';
import huemorme from '@/assets/MooreKismet-HuemorMe_Final.jpg';

const posts = [{
  image: dogwater,
  link: 'https://sablevalley.co/Dogwater',
}, {
  image: huemorme,
  link: 'https://sablevalley.co/huemorme',
}, {
  image: rmxs,
  link: 'https://sablevalley.co/FloorspaceRMX-S',
}, {
  image: forwardOnly,
  link: 'https://sablevalley.co/ForwardOnly',
}, {
  image: monarchRemixes,
  link: 'https://sablevalley.co/monarchremixes',
}, {
  image: deadcrow,
  link: 'https://sablevalley.co/REACTOR',
}, {
  image: playremaster,
  link: 'https://rlgrime.lnk.to/PLAY',
}, {
  image: controlfreak,
  link: 'https://sablevalley.co/NOFORTUNE',
}, {
  image: SVSummerVol4,
  link: 'https://sablevalley.co/SVSummerVol4',
}, {
  image: niteharts,
  link: 'https://sablevalley.co/niteharts',
}, {
  image: WINK,
  link: 'https://sablevalley.co/wink',
}, {
  image: Monarch,
  link: 'https://sablevalley.co/monarch',
}, {
  image: floorspace,
  link: 'https://sablevalley.co/floorspace',
}, {
  image: HalloweenXI,
  link: 'https://sablevalley.co/HalloweenXI',
}, {
  image: SummerVol3,
  link: 'https://sablevalley.co/SVSummerVol3',
}, {
  image: HalloweenX,
  link: 'http://rlgrime.co/HalloweenX',
}, {
  image: FinalOutreachPoster,
  link: 'http://sablevalley.co/nightrealm',
}, {
  image: Halloween2,
  link: 'http://sablevalley.co/SVSummerVol2',
}, {
  image: Halloween,
  link: 'http://smarturl.it/HalloweenIX',
}, {
  image: SVSummer,
  link: 'https://fanlink.to/SVSummerVol1',
}, {
  image: Juelz,
  link: 'https://ffm.to/highoctane',
}, {
  image: Montell,
  link: 'https://smarturl.it/Montell2099FORCES',
}];
const tourDates = [{
  date: 'September 3',
  city: 'Columbus, OH',
  venue: 'The Bluestone',
  URL: 'https://www.tixr.com/groups/prime/events/rl-grime-27614',
}, {

  date: 'September 4',
  city: 'Lincoln, NE ',
  venue: 'Royal Grove Outdoor Lot',
  URL: 'https://www.tixr.com/groups/stationtostation/events/rl-grime-at-the-royal-grove-outdoors-27638',
}, {
  date: 'September 9',
  city: 'Nashville, TN  ',
  venue: 'Marathon Music Works',
  URL: 'https://www.ticketweb.com/event/rl-grime-marathon-music-works-tickets/11226655?pl=marathonmusicworks',
}, {

  date: 'September 10',
  city: 'Indianapolis, IN  ',
  venue: 'Pavilion at Pan Am',
  URL: 'https://rlgrimeindy.eventbrite.com',
}, {

  date: 'September 11',
  city: 'Charlotte, NC   ',
  venue: 'Blackbox Theatre',
  URL: 'https://hive.co/l/rlgrime0911',
}, {

  date: 'September 17',
  city: 'Gainesville, FL   ',
  venue: 'Vivid Sky Warehouse',
  URL: 'https://tixr.com/e/27519',
}, {

  date: 'September 19',
  city: 'Tallahassee, FL     ',
  venue: 'The Strip Block Party',
  URL: 'https://tixr.com/e/27527',
}, {

  date: 'October 1',
  city: 'Denver, CO   ',
  venue: 'Mission Ballroom',
  URL: 'https://www.axs.com/events/408929/rl-grime-tickets',
}, {

  date: 'October 2',
  city: 'Atlanta, GA    ',
  venue: 'Georgia Freight Depot Block Party',
  URL: ' https://events.collectivpresents.com/RLGRIMEATL2021',
}, {

  date: 'October 23',
  city: 'San Jose, CA   ',
  venue: 'San Jose Civic Auditorium',
  URL: 'https://www.axs.com/events/409050/rl-grime-tickets?skin=goldenvoice',
}, {

  date: 'November 5',
  city: 'Brooklyn, NY   ',
  venue: 'Great Hall at Avant Gardner',
  URL: 'https://link.dice.fm/fQwXuj2t3hb',
}, {

  date: 'November 6',
  city: 'Washington, DC  ',
  venue: 'Echostage',
  URL: 'https://www.ticketmaster.com/event/15005AF4EF2C3F28',
}];

export default {
  name: 'TourSignup',
  props: {},
  data() {
    return {
      mounted: false,
      successful: false,
      posts,
      tourDates,
      CommunityOutreachPoster,
      featuredImage: false,
      featuredTour: false,
    };
  },
  mounted() {
    setTimeout(() => {
      this.mounted = true;
      if (laylo) {
        console.log(laylo);
        laylo.initialize();
      }
    }, 10);
    if ('success' in this.$route.query) {
      this.successful = true;
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style lang="scss" scoped>
.iframe-wrap {
  max-width: 34rem;
  margin: 0 auto;
  text-align: center;
  width: 100%;
  margin-top: 2rem;
}

.posts {
  padding-top: 6rem;

}

.post-large {
  max-width: 32rem;
  overflow: hidden;
  display: block;
  margin: 0 auto;
  opacity: 0;
  animation: 800ms ease fadeInUp forwards;
  transform: scale(1);
  transition: 200ms ease transform;
  padding-top: 2rem;

  .post-image {
    width: 100%;
    height: 0;
    padding-bottom: 100%;
    background-size: contain;
    background-position: center bottom;
    background-repeat: no-repeat;
  }

  .post-image-large {
    padding-bottom: 125%;

  }
}

.post {
  max-width: 32rem;
  overflow: hidden;
  display: block;
  margin: 0 auto;
  opacity: 0;
  animation: 800ms ease fadeInUp forwards;

  .post-image {
    width: 100%;
    height: 0;
    padding-bottom: 100%;
    background-size: contain;
    background-position: center bottom;
    background-repeat: no-repeat;
  }

  .post-image-large {
    padding-bottom: 125%;

  }

  padding: 2rem 2rem;
  transform: scale(1);
  transition: 200ms ease transform;

  &:hover {
    transform: scale(1.02);
  }
}

.shop-nav {
  text-align: center;
  padding: 0.5rem;
  padding-top: 3rem;

  a {
    color: black;
    font-weight: bold;
    text-decoration: none;
    text-transform: uppercase;
    border-bottom: 2px solid black;

    &:hover {
      opacity: 0.6;
    }
  }
}

.shop-icon {
  display: inline-block;
  font-size: 0.8em;
  transform: rotate(-45deg) translate(0, -2px);
  transform-origin: center;
}

.wrap {
  padding: 1rem;
  font-size: 1rem;
  text-align: center;

  .blurred-show {
    background-image: url('../assets/blurred-show-mobile.png');

    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    height: 21vw;
    width: 100%;
    margin: 0 -1rem;

    @include desktop {
      height: 3rem;
      background-size: cover;
      background-image: url('../assets/blurred-show.png');

    }
  }

  .lines {
    background-image: url('../assets/svrdlines.png');
    background-size: contain;
    background-position: left;
    background-repeat: repeat-x;
    height: 1rem;
    width: 100%;
  }

  .tour-dates {
    text-align: left;
    padding: 0;
    padding-bottom: 2rem;
    padding-top: 1rem;
    width: 100%;
    max-width: 54rem;
    margin: 0 auto;
    font-size: 0.8rem;
    font-weight: bold;
    letter-spacing: 0.01rem;

    .tour-dates-wrap {
      padding: 1rem 0;
    }

    .tour-date {
      padding: 1rem 0;
      // display: flex;
      // flex-direction: row;
      line-height: 1.2;
      color: $black;
      text-decoration: none;
      display: grid;
      grid-template-columns: 1fr 1fr;
      border-bottom: 1px solid rgba(0, 0, 0, 0.2);

      @include desktop {
        grid-template-columns: 1fr 1fr 2fr 1fr;
        padding: 0.6rem 0;

      }

      &:hover {
        text-decoration: line-through;
      }

      .show-date {
        padding: 0;

        padding-right: 1rem;
      }

      .show-city {
        padding: 0;

        padding-right: 1rem;
      }

      .show-venue {
        padding: 0;

        padding-right: 1rem;
      }

      .show-artists {
        padding: 0;

        &.blurred {
          filter: blur(10px);
        }

      }
    }
  }

  .tour-title {
    flex: 0 1 auto;
    width: 16rem;
    opacity: 0;
    animation: 800ms fadeInUp forwards 400ms;

  }

  .tour-title-inner {
    background-image: url('../assets/svrdlogo.png');
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    height: 10rem;
    width: 100%;
    margin: 0 auto;

  }

  .logo {
    padding-top: 5rem;
    height: 10rem;
    width: 100%;
    z-index: 100;

    opacity: 1;
    max-width: 54rem;
    margin: 0 auto;
    margin-bottom: 2rem;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    .logo-inner {
      flex: 0 0 auto;
      background-image: url('../assets/svlogo.png');
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      height: 8rem;
      width: 8rem;
      opacity: 0;
      animation: 1500ms fadeInUpTurn linear forwards;
    }

    // animation-name: spin;
    // animation-duration: 20000ms;
    // animation-iteration-count: infinite;
    // animation-timing-function: linear;
  }

  .copy {
    background-image: url('../assets/copy.png');
    background-size: contain;
    background-position: center bottom;
    background-repeat: no-repeat;
    height: 5rem;
    width: 100%;
    opacity: 0;
    animation: 800ms fadeInUp ease forwards 600ms;

  }
}

.inner {
  max-width: 28rem;
  margin: 0 auto;
  width: 100%;
  font-weight: bold;
  font-size: 0.8rem;

}

.email-input {
  padding: 0.625rem;
  font-size: 16px;

  @include desktop {
    font-size: 1rem;

  }

  background: transparent;
  font-weight: bold;
  border: none;
  width: 100%;
  text-align: left;
  margin: 0;
  -webkit-appearance: none !important;

}

.input-wrap {
  position: relative;
  margin: 1rem 0;
  margin-bottom: 2rem;
  border: 2px solid $black;
  // border-left: none;
  // border-right: none;
  border-bottom: 2px solid $black;
  opacity: 0;
  animation: 800ms fadeInUp ease forwards 1000ms;
  border-radius: 2px;
}

.submit {
  padding: 0 1rem;
  // padding-top: 0.25rem;
  bottom: 0;
  font-size: 0.8rem;
  -webkit-appearance: none !important;
  border-radius: 0;
  cursor: pointer;
  background: $black;
  color: $bg;
  position: absolute;
  right: 0;
  top: 0;
  transition: 150ms ease all;

  &:hover {
    opacity: 1;
    transform: scale(1.02);
  }

}

.iframe-wrap {

  iframe {
    width: calc(100vw - 4rem);
    height: calc((100vw - 4rem) * 9 / 16);

    @include desktop {
      width: calc(80vw - 4rem);
      height: calc((80vw - 4rem) * 9 / 16);
    }

    margin: 0 auto;
    display: block;
    padding: 0;

  }
}

.section-black {
  background: $black;
  padding: 4rem 2rem;
  margin: 2rem 0 4rem;
  position: relative;
  z-index: 1000;

}

.success-message {
  padding: 1rem;
  margin-top: 2rem;
  background: $black;

  color: $bg;
}

@keyframes fadeInUpTurn {
  0% {
    transform: translate(0, 0) rotate(40deg);
    opacity: 0;
  }

  100% {
    transform: translate(0, 0) rotate(0deg);
    opacity: 1;

  }
}

@keyframes fadeInUp {
  from {
    transform: translate(0, 12px);
    opacity: 0;
  }

  to {
    transform: none;
    opacity: 1;
  }
}

@keyframes spin {
  from {
    transform: translate(-50%, 0) rotate(0deg);
  }

  to {
    transform: translate(-50%, 0) rotate(360deg);
  }
}
</style>
