<template>
  <div id="app">
    <Frame />
    <div class="axis">
      <div class="vaxis"></div>
      <div class="tmargin"></div>
      <div class="bmargin"></div>

      <div class="haxis"></div>
      <div class="rmargin"></div>
      <div class="lmargin"></div>
    </div>
    <div class="borders_">
      <div class="border_t"></div>
      <div class="border_b"></div>
      <div class="border_r"></div>
      <div class="border_l"></div>
    </div>

  </div>


</template>

<script>
import Frame from './components/Frame.vue';

export default {
  name: 'app',
  components: {
    Frame,
  },
};
</script>

<style lang="scss">
  @import "@/scss/global/_typography.scss";

  #app {
    color: $black;
  }
</style>
