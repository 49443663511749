<template>
  <div class="hello" :class="{'loading': loading,'modal-open': modalOpen, 'modal-closing': modalClosing}">
    <div class="main-view">
      <router-view></router-view>
    </div>

    <div class="copyright">
      <div class="copyright-text">
        Copyright 2021 Sable Valley LLC. All rights reserved.
      </div>
      <!--
      <br class="mobile-break"/>
      <div class="copyright-terms" :style="{'background-image': `url(${copyrightTerms})`}">
        <router-link to="/about">Terms of Use</router-link> |
      </div><div class="copyright-privacy" :style="{'background-image': `url(${copyrightPrivacy})`}">
        <router-link to="/about">Privacy Policy</router-link>
      </div> -->
    </div>
    <div class="links">
      <div class="links-inner">
        <div class="link">
          <!-- <router-link to="/music"> -->
          <a href="https://open.spotify.com/playlist/0B7zobkktYJYJbdvibouLh?si=KUDs78brRry6CmGijHWgdA">
            <div class="link-inner-about">
              MUSIC
            </div>
          </a>
          <!-- </router-link> -->
        </div>

        <a>
          <router-link to="/">
            <div class="logo"></div>
          </router-link>
        </a>

        <div class="link">
          <a href="https://shop.sablevalley.com/">
            <div class="link-inner-membership" >
              SHOP
            </div>
          </a>
        </div>
      </div>

    </div>

  </div>
</template>

<script>
import FontFaceObserver from 'fontfaceobserver';

import copyright from '@/assets/copy2020.png';
import copyrightTerms from '@/assets/copyrightTerms.png';
import copyrightPrivacy from '@/assets/copyrightPrivacy.png';
import menuAboutImage from '@/assets/about.png';
import menuDemoImage from '@/assets/demo.png';
import menuMemberImage from '@/assets/member.png';
import menuMerchImage from '@/assets/merch.png';

const font = new FontFaceObserver('title');

export default {
  name: 'Frame',
  props: {},
  data() {
    return {
      year: new Date().getFullYear(),
      email: 'wendygarfo@gmail.com',
      modalOpen: false,
      modalClosing: false,
      timeout: -1,
      loading: true,
      copyright,
      copyrightTerms,
      copyrightPrivacy,
      menuAboutImage,
      menuDemoImage,
      menuMemberImage,
      menuMerchImage,
    };
  },
  mounted() {
    console.log(this.$route.path);
    clearTimeout(this.timeout);
    font.load().then(() => {
      this.loading = false;
    });
  },
  methods: {
    openModal() {
      this.modalOpen = true;
      console.log('modal open');
    },
    handleSuccess() {
      this.modalOpen = true;
      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        this.closeModal();
      }, 1000);
    },
    closeModal() {
      console.log(1);
      clearTimeout(this.timeout);
      this.modalClosing = true;
      this.timeout = setTimeout(() => {
        this.modalClosing = false;
        this.modalOpen = false;
      }, 350);
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.mobile-break {
  display: block;
  @include desktop {
    display: none;
  }
}
.main-view {
  opacity: 0;
  animation: 1000ms animateVideo $easeOutCubic forwards 0ms;
}
  .copyright {
    font-weight: bold;
    bottom: 0;
    left: 0;
    right:0;
    text-align: center;
    font-size: 0.8rem;
    opacity: 1;
    // transform: translate(0, 50%);
    animation: 1000ms fadeIn linear forwards 0ms;
    padding: 1rem;
    padding-top: 0;
    line-height: 2;
    .copyright-text,
    .copyright-terms,
    .copyright-privacy {
        color: transparent;
        background-size: contain;
        background-repeat: no-repeat;
        display: inline-block;
        background:  none;
        opacity: 1;
        color: $black;

        a {
          // color: transparent;
          color: $black;
        }
    }
    a {
      text-decoration: none;
      color: $black;
      border-bottom: 1px solid;
    }
  }
  .links {
    position: absolute;
    top: 0;
    right: 0;
    text-transform: uppercase;
    font-weight: bolder;
    padding: 0 $mobileMargin;
    text-align: right;

    font-family: Helvetica;
    font-weight: bold;
    left: 0;
    height: 9rem;

    .links-inner {
      max-width: 54rem;
      padding: 2rem $mobileMargin 0;
      margin: 0 auto;
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-direction: row;

    }

    .link {
      opacity: 0;
      animation: 800ms animateLink forwards 0ms;
      background: linear-gradient(to right, black, black);
      background-size: 0px 2px;
      background-repeat: no-repeat;
      background-position: right center;
      @include desktop {
        background-position: center bottom;
      }
      transition: 250ms $easeInOutQuint all;
      &:hover {
        background-size: 100% 2px;
      }
    }
    a {
      color: $black !important;
      text-decoration: none;
    }
    .link-inner-demo,
    .link-inner-about,
    .link-inner-membership {
      background-size: contain;
      background-repeat: no-repeat;
      background-position: right center;
      display: inline-block;
    }

  }


  .logo {
    background-image: url('../assets/svlogo.png');
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    height: 7rem;
    width: 7rem;
    z-index: 100;
    position: absolute;
    top: 1rem;
    left: 50%;
    opacity: 0;
    animation: 800ms animateLogo forwards 0ms;

  }
  .video {
    text-align: center;
    padding: 0 $mobileMargin;
    @include desktop {
      padding: 0 $margin;
    }
    iframe {
      width: 100%;
      border: none;
      margin: 0 auto;
      height: 56vw;
      @include desktop {
        height: 50vh;

        max-width: 88.88vh;

      }
    }
    margin: 0 auto;

  }
  .application-button {
    text-align: center;
    height: calc(25vh - 1rem);
    display: flex;
    align-items: center;
    justify-content: center;
    button {
      cursor: pointer;
      padding: 1rem;
      padding-bottom: 0.9rem;
      border: 2px solid $main;
      background: $bg;
      font-size: 1rem;
      font-family: 'text';

    }
  }
  .application {
    padding: 1rem $mobileMargin;
    @include desktop {
      padding: 1rem $margin;
    }
    .close-wrap {
      text-align: right;
    }
    .close {
      padding: 1rem;
      border: 2px solid $white;
      font-family: 'text';
      text-transform: uppercase;
      font-size: 1rem;
      background: transparent;
      color: $white;
    }
    .title {
      font-size: 3rem;
      padding-top: 1rem;
    }
    button {
      cursor: pointer;
      padding: 1rem;
      padding-bottom: 0.9rem;
      border: 2px solid $main;
      background: transparent;
      font-size: 1rem;
      font-family: 'text';
      border: 2px solid $white;

      color: $white;
    }
    button:hover {
      background: $white;
      color: $main;
    }
  }
  .questions {
    .question {
      padding-bottom: 1rem;
    }

    input {
      border: 2px solid $white;
      padding: 0.5rem;
      color: $white;
      font-family: 'text';
    }
    textarea {
      background: transparent;
      border: none;
      outline: none;
      font-size: 1rem;
      color: $white;
      border: 2px solid $white;
      padding: 0.5rem;
      width: 100%;
      font-family: 'text';
    }
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;

    }
    100% {
      opacity: 1;
    }
  }
  @keyframes animateCloseModalWrap {
    0% {
      opacity: 1;

    }
    100% {
      opacity: 0;
    }
  }
  @keyframes animateModal {
    0% {
      opacity: 0;
      transform: translate(0, 400px);
    }
    100% {
      opacity: 1;
      transform: translate(0, 0);
    }
  }
  @keyframes downFromMiddle {
    0% {
      // transform: translate(0, -50vh);
      bottom: 50vh;
    }
    100% {
      transform: translate(0, 0);
      bottom: 0;
    }
  }
  @keyframes animateVideo {
    0% {
      opacity: 0;
      transform: translate(0, -10px);
    }
    100% {
      transform: translate(0, 0);
      opacity: 1;
    }
  }
  @keyframes animateLogo {
    0% {
      opacity: 0;
      transform: translate(-50%, 0px) rotate(-47deg);
    }
    100% {
      transform: translate(-50%, 0) rotate(0);
      opacity: 1;
    }
  }
  @keyframes animateLink {
    0% {
      opacity: 0;
      transform: translate(0, -10px);
    }
    100% {
      transform: translate(0, 0);
      opacity: 1;
    }
  }

</style>
