<template>
  <div class="video-wrap">
    <div class="video">
      <iframe class="iframe" width="100%" height="465" scrolling="no" frameborder="no" src="https://w.soundcloud.com/player/?url=https://soundcloud.com/sablevalley&amp;auto_play=false&amp;buying=true&amp;liking=true&amp;download=true&amp;sharing=true&amp;show_artwork=true&amp;show_comments=true&amp;show_playcount=true&amp;show_user=true&amp;hide_related=false&amp;visual=false&amp;start_track=0&amp;callback=true"></iframe>
    </div>

  </div>

</template>

<script>
export default {
  name: 'Home',
  props: {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

.video-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-top: 9rem;
  padding-bottom: 9rem;
  width: 100%;
}
.video {
  text-align: center;
  padding: 0 $mobileMargin;
  max-height: calc(50vh - 11rem);
  @include desktop {
    padding: 0 $margin;
  }
  width: 100%;
  max-width: 54rem;
  iframe {
    width: 100%;
    border: none;
    margin: 0 auto;

  }
  margin: 0 auto;
  opacity: 0;
  animation: 1s animateVideo $easeInOutSine forwards 0ms;
}
@keyframes animateSlogan {
  from {
    transform: translate(0, 0);
  }
  to {
    transform: translate(100%, 0);
  }
}
@keyframes animateVideo {
  0% {
    opacity: 0;
    max-height: calc(50vh - 12rem);
    transform: translate(0, 20px);
  }
  50% {
    opacity: 0;

  }
  100% {
    opacity: 1;
    max-height: 60vh;
    transform: translate(0, 0);
  }
}

</style>
