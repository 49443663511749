import VueRouter from 'vue-router';
import Home from '@/components/Home.vue';
import About from '@/components/About.vue';
import Merch from '@/components/Merch.vue';
import Membership from '@/components/Membership.vue';
import TourSignup from '@/components/TourSignup.vue';
import Music from '@/components/Music.vue';


import ApplicationForm from '@/components/ApplicationForm.vue';

const routes = [
  {
    path: '/',
    component: TourSignup,
    meta: {
      title: 'Sable Valley',
    },
  }, {
    path: '/about',
    component: About,
    meta: {
      title: 'Sable Valley | About',
    },
  }, {
    path: '/music',
    component: Music,
    meta: {
      title: 'Sable Valley | Music',
    },
  }, {
    path: '/membership',
    component: Membership,
    meta: {
      title: 'Sable Valley | Membership',
    },
  }, {
    path: '/merch',
    component: Merch,
    meta: {
      title: 'Sable Valley | Merchandise',
    },
  }, {
    path: '/warehousetour',
    component: TourSignup,
    meta: {
      title: 'Sable Valley | Warehouse Tour',
    },
  },
];

const router = new VueRouter({
  mode: 'history',
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.meta && to.meta.title) {
    document.title = to.meta.title;
  }
  next();
});

export default router;
