<template>
  <div class="video-wrap">
    <div class="video">
      <iframe
        src="https://www.youtube.com/embed/gTAvRsDJe7w?autoplay=1&controls=0&rel=0&showinfo=0"
        width=""
        height=""
        allow="autoplay"
      >
      </iframe>
    </div>

      <div class="slogan">
        <div class="slogan-inner"></div>
        <div class="slogan-cover"></div>
      </div>
  </div>

</template>

<script>
export default {
  name: 'Home',
  props: {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.slogan {
  position: relative;
  .slogan-inner {
    width: 100%;
    height: 2rem;
    background-image: url('../assets/slogan.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }
  .slogan-cover {
    background: $bg;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    animation: 2000ms animateSlogan linear forwards 500ms;
  }
  max-width: 50rem;
  width: 100%;
  overflow: hidden;
  margin: 0 auto;
  margin-top: 2rem;
  padding-bottom: 1rem;
}
.video-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-top: 9rem;
  width: 100%;
}
.video {
  text-align: center;
  padding: 0 $mobileMargin;
  max-height: calc(50vh - 11rem);
  @include desktop {
    padding: 0 $margin;
  }
  width: 100%;
  iframe {
    width: 100%;
    border: none;
    margin: 0 auto;
    height: 56vw;
    @include desktop {
      height: 50vh;
      max-width: 88.88vh;
    }
  }
  margin: 0 auto;
  opacity: 0;
  animation: 1000ms animateVideo $easeInOutSine forwards 2500ms;
}
@keyframes animateSlogan {
  from {
    transform: translate(0, 0);
  }
  to {
    transform: translate(100%, 0);
  }
}
@keyframes animateVideo {
  0% {
    opacity: 0;
    max-height: calc(50vh - 12rem);
    transform: translate(0, 20px);
  }
  50% {
    opacity: 0;

  }
  100% {
    opacity: 1;
    max-height: 60vh;
    transform: translate(0, 0);
  }
}

</style>
